import React, { useCallback, useEffect } from "react";

import { updateUserMeta } from "@actions";
import { StatusPill, gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import GroupItem from "./GroupItem";
import { toggleInArray } from "./helpers";
import Item from "./Item";
import type { ListAnalyticDomain, ListInsightsDashboard } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";

type SubjectItemProps = {
  subject: ListAnalyticDomain;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  insightsDashboards: ListInsightsDashboard[];
  openGroups: string[];
  setOpenGroups: (value: string[]) => void;
  isLoading: boolean;
  updateUserMeta: (key: string, value: string | string[]) => void;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
};

const SubjectItem = ({
  subject,
  collapsed,
  toggleCollapsed,
  insightsDashboards,
  openGroups,
  setOpenGroups,
  isLoading,
  updateUserMeta,
  selectedObject,
  setSelectedObject,
}: SubjectItemProps) => {
  const location = useLocation();

  const handleGroupToggle = useCallback(
    (subjectUuid: string) => {
      const updatedSubjects = toggleInArray(openGroups, subjectUuid);
      setOpenGroups(updatedSubjects);
      updateUserMeta("leapInsightsOpenedGroups", updatedSubjects);
    },
    [openGroups],
  );

  useEffect(() => {
    if (
      collapsed &&
      location.pathname.includes(`${subject.path}`) &&
      toggleCollapsed
    ) {
      toggleCollapsed();
    }
  }, [location.pathname]);

  const subjectPath = `${LEAP_INSIGHTS_URLS.baseUrl}/${subject.path}`;

  const getDashboardCount = () => {
    let count = 0;
    subject.subdomains.forEach(
      (subdomain) =>
        (count += insightsDashboards.filter(
          (dashboard) => dashboard.subdomain.uuid === subdomain.uuid,
        ).length),
    );
    return count.toString();
  };

  return (
    <div key={subject.name}>
      <Item
        icon={subject.icon}
        text={subject.name}
        path={subjectPath}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        style={{ fontSize: 18 }}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
      >
        {!isLoading && (
          <StatusPill
            small
            type={
              subjectPath === window.location.pathname ? "primary" : "default"
            }
            text={getDashboardCount()}
            style={{ marginLeft: gridSpacing[2], height: 20 }}
          />
        )}
      </Item>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!collapsed &&
          subject.subdomains.map((subdomain) => (
            <GroupItem
              key={subdomain.name}
              group={subdomain}
              subjectPath={subject.path}
              collapsed={!openGroups.includes(subdomain.uuid)}
              toggleCollapsed={() => handleGroupToggle(subdomain.uuid)}
              insightsDashboards={insightsDashboards
                .filter(
                  (dashboard) => dashboard.subdomain.uuid === subdomain.uuid,
                )
                .sort((a, b) => a.rank - b.rank)}
              isLoading={isLoading}
              selectedObject={selectedObject}
              setSelectedObject={setSelectedObject}
            />
          ))}
      </div>
    </div>
  );
};

export default connect(null, {
  updateUserMeta,
})(SubjectItem);
