import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { styled } from "styled-components";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";
import { colorTheme, numberWithCommas } from "~/src/utils";

type PieChartProps = {
  title: string;
  subtitle?: string;
  data: {
    name: string;
    amount: number;
  }[];
  toolTip?: string;
  label?: string;
  loading?: boolean;
  error?: string | null;
};

const PieChartWrapper = styled(Wrapper)`
  min-height: 147px;
  min-width: 251px;
`;

const PieChart = memo(
  ({
    title,
    subtitle,
    data,
    toolTip,
    label,
    loading,
    error,
  }: PieChartProps) => {
    let totalAmount: number | null = null;
    data.forEach((o) => {
      if (o.amount) {
        if (totalAmount === null) totalAmount = 0;
        totalAmount += o.amount;
      }
    });

    const seriesData: Highcharts.PointOptionsObject[] = data.map((item) => {
      return {
        name: item.name,
        y: item.amount,
      };
    });

    const getSubtitle = () => {
      if (totalAmount === null) return undefined;
      return `<div style="text-align: center;"><span style="font-size: 18px; color: ${colorTheme(
        "neutralL1",
      )};">Total</span>
        <br>
        <span style="font-size: 20px; font-family: Manrope; color: ${colorTheme(
          "primaryD2",
        )}; font-weight: 700;">
            ${numberWithCommas(totalAmount)}
        </span></div>`;
    };

    const renderPieChart = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      const options: Highcharts.Options = {
        chart: {
          type: "pie",
        },
        subtitle: {
          useHTML: true,
          text: getSubtitle(),
          floating: true,
          verticalAlign: "middle",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            borderWidth: 1,
            cursor: "pointer",
            showInLegend: false,
            dataLabels: {
              enabled: true,
              format: `{point.name} ({point.percentage:.2f}%)`,
            },
          },
          series: {
            name: label || "Count",
            size: "70%",
            innerSize: "55%",
          } as Highcharts.PlotSeriesOptions,
        },
        legend: {
          align: "left",
        },
        series: [{ type: "pie", data: seriesData }],
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      return <HighchartsReact highcharts={Highcharts} options={options} />;
    };

    return (
      <PieChartWrapper>
        <Header title={title} toolTip={toolTip} subtitle={subtitle} />
        {renderPieChart()}
      </PieChartWrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default PieChart;
