export const convertPieChartData = (
  labelKey: string,
  valueKey: string,
  // Need to disable eslint for this because all the data being typed will have a const string name

  data?: Record<any, string>[],
): {
  name: string;
  amount: number;
}[] => {
  if (!data || !labelKey || !valueKey) {
    return [];
  }

  return data.map((item) => {
    return {
      name: item[labelKey] || `"" (Empty String)`,
      amount: parseInt(item[valueKey]),
    };
  });
};
