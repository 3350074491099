import React from "react";

import { SubTextCell } from "@lucernahealth/lucerna-health-ui";
import moment from "moment";
import { styled } from "styled-components";

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const UserRecordColumns = [
  {
    Header: "Last Modified",
    accessor: "modified",
    width: 70,
    Cell: (props) =>
      props.value ? (
        <SubTextCell
          content={moment(props.value).format("MM/DD/YYYY h:mm a")}
          subText={props.row.original.modified_by_name}
        />
      ) : (
        ""
      ),
  },
];
