import axios from "axios";
import moment from "moment";
import slugify from "slugify";
import { styled } from "styled-components";

import store from "../store";
import defaultColorTheme from "./defaultColorTheme";

function accessProperty(path, obj) {
  return path.split(".").reduce((acc, cur) => acc[cur], obj);
}

function getStringWidth(string, font) {
  if (!string) return 0;

  const offScreenElement = document.createElement("span");
  offScreenElement.style.whiteSpace = "nowrap";
  offScreenElement.style.font = font;
  offScreenElement.textContent = string;
  document.body.appendChild(offScreenElement);
  const width = offScreenElement.offsetWidth;
  document.body.removeChild(offScreenElement);
  return width;
}

export * from "./customColors";
export { default as DefaultColorTheme } from "./defaultColorTheme";
export { default as StatesJson } from "./States";
export { default as TableThemes } from "./TableThemes";
export * from "./UserRecordColumns";

export const uploadAwsFile = ({
  url,
  fields,
  file,
  callback,
  errorCallback,
}) => {
  const formData = new FormData();
  Object.keys(fields).forEach((fieldKey) => {
    formData.append(fieldKey, fields[fieldKey]);
  });

  formData.append("file", file);

  const awsInstance = axios.create();
  delete awsInstance.defaults.headers.common["Authorization"];

  return awsInstance
    .post(url, formData)
    .then(callback)
    .catch((err) => {
      errorCallback(err);
      console.error(err);
    });
};

export const TimeZoneOptions = [
  { label: "Atlantic (UTC-04:00)", value: "AST" },
  { label: "Eastern (UTC-05:00)", value: "EST" },
  { label: "Central (UTC-06:00)", value: "CST" },
  { label: "Mountain (UTC-07:00)", value: "MST" },
  { label: "Pacific (UTC-08:00)", value: "PST" },
  { label: "Alaska (UTC-09:00)", value: "AKST" },
  { label: "Hawaii (UTC-10:00)", value: "HST" },
];

export function checkPermissions(permission) {
  const userPermissions = store.getState()?.main?.user?.permissions;

  if (!permission || !userPermissions) {
    return false;
  }

  if (Array.isArray(permission)) {
    return !!userPermissions.find((obj) => permission.includes(obj));
  } else {
    return userPermissions.includes(permission);
  }
}

// Super simple color adjuster
// Takes color and percentage to adjust the color by -/+ integer
export function colorAdjuster(color, percent) {
  if (!Number.isInteger(percent) || typeof color !== "string") {
    return "#fff";
  }

  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

// Verification of s3 Buckets
export function verifyS3Bucket(objectPrefix) {
  if (objectPrefix.trim().length === 0) {
    return "S3 prefix is required";
  } else if (objectPrefix.length < 4) {
    return "S3 prefix must be more than 3 characters";
  } else if (objectPrefix.length > 62) {
    return "S3 prefix must be less than 63 characters";
  } else if (objectPrefix.match(/[^-a-z0-9_]+/)) {
    return "Only lowercase letters, numbers, underscores, and hyphens";
  } else if (!objectPrefix.match(/^\w/)) {
    return "S3 prefix must start with a character or number";
  } else if (
    objectPrefix.match(
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
    )
  ) {
    return "S3 prefix must not be formatted as an IP address";
  } else {
    return "valid";
  }
}

export function toAlphaNum(input) {
  return input.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
}

export function numberWithCommas(x) {
  if (x === null || x === undefined) return "0";

  if (typeof x === "string") {
    x = parseFloat(x);
  }

  if (typeof x !== "number") {
    return "Invalid Input";
  }

  return x.toLocaleString(undefined, { maximumFractionDigits: 20 });
}

export function capitalizeFirstLetters(input) {
  if (input) {
    return input
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
  return "";
}

export function makeHumanReadable(input) {
  if (input && typeof input === "string") {
    return capitalizeFirstLetters(
      input.replaceAll("_", " ").replaceAll("-", " "),
    );
  }
  return "";
}

export function capitalizeOnlyFirstLetter(input) {
  if (input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  return "";
}

export function humanFileSize(size) {
  if (!size) return 0;

  let k = 1000;
  let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = Math.floor(Math.log(size) / Math.log(k));
  let output = parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];

  if (output.includes("NaN") || output.includes("undefined")) {
    return "";
  }

  return output;
}

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const humanReadableTime = (value) => {
  if (!value) return "";

  const duration = moment.duration(value);
  const asSeconds = duration.asSeconds();
  const asMinutes = duration.asMinutes();
  const asHours = duration.asHours();
  const asDays = duration.asDays();
  if (asSeconds < 60) {
    return "Less than one minute";
  } else if (asHours < 1) {
    return `${Math.floor(asMinutes)} minutes`;
  } else if (asDays < 1) {
    return `${duration.hours()} hours ${duration.minutes()} minutes`;
  } else {
    return `${duration.days()} days ${duration.hours()} hours ${duration.minutes()} minutes`;
  }
};

export const isDateBefore = (dateBefore, dateAfter) =>
  moment(dateBefore).isBefore(dateAfter);

// Make a deep copy of the object without the properties specified
export const objectWithoutProperties = (obj, properties) => {
  const objClone = { ...obj };
  properties.forEach((prop) => {
    delete objClone[prop];
  });
  return objClone;
};

export const deepCopy = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
};

export const slugifyString = (string) => {
  string = string.replace(/[<>$%&|]+/g, "");
  return slugify(string, {
    replacement: "_",
    lower: true,
    remove: /[*+~.()'"!:@$%&<>]/g,
    strict: true,
  });
};

export const slugifyUrl = (string) => {
  string = string.replace(/[<>$%&|]+/g, "");
  return slugify(string, {
    replacement: "-",
    lower: true,
    remove: /[*+~.()'"!:@$%&<>]/g,
    strict: true,
  });
};

export const HorizontalLine = styled.div`
  height: 33px;
  width: 30px;
  border-bottom: 2px solid ${defaultColorTheme["neutralL2"]}; // Using color theme in store will blow up
`;

export const VerticalLine = styled.div`
  height: ${(props) => (props.lastChild ? "50px" : "inherit")};
  width: 15px;
  border-right: 2px solid ${defaultColorTheme["neutralL2"]}; // Using color theme in store will blow up
`;

/**
 *
 * @param item
 * @returns {string}
 */
export const colorTheme = (item) => {
  const state = store.getState();

  if (!item) {
    return state.main.colorTheme;
  }

  let color = null;
  if (!state.main.colorTheme) {
    color = defaultColorTheme[item];
  } else {
    color = state.main.colorTheme[item];
  }

  if (!color) {
    console.error(`${item} color does not exists`);
  }
  return color || "cyan";
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const Divider = styled.hr`
  width: 100%;
  border-top: 1px solid ${defaultColorTheme["neutralL4"]};
  border-left: 0px;
  margin: 10px 0px 30px 0px;
`;

export const translateToSuffix = (number) => {
  const ordinalRules = new Intl.PluralRules("en", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };
  const suffix = suffixes[ordinalRules.select(number)];
  return number + suffix;
};

export const validateOutcomeTests = (outcome_tests, setErrors) => {
  const _errors = {};
  var valid = true;
  var seen_values_map = {};
  outcome_tests.forEach((test, index) => {
    _errors[index] = {};
    if (test === null || !test.test_type) {
      _errors[index]["test_type"] = "Test type is required";
    }
    if (!test?.outcome) {
      _errors[index]["outcome"] = "Outcome is required";
    }
    if (!test?.value) {
      _errors[index]["value"] = "Value is required";
    }
    if (test?.value && isNaN(test.value)) {
      _errors[index]["value"] = "Value must be a number";
    }

    if (test?.test_type && test?.value) {
      var test_type = test.test_type;
      var value = parseInt(test.value);
      if (test_type.startsWith("min")) {
        var inverse_test_type = test_type.replace("min", "max");
        if (value < 0) {
          _errors[index]["value"] = "Value must be greater than 0";
        }
        if (!(inverse_test_type in seen_values_map)) {
          seen_values_map[test_type] = value;
        } else {
          if (seen_values_map[inverse_test_type] < value) {
            _errors[index]["value"] =
              `Value must be than ${capitalizeFirstLetters(inverse_test_type)}`;
          }
        }
      } else {
        inverse_test_type = test_type.replace("max", "min");
        if (value < 1) {
          _errors[index]["value"] = `Value must be greater than 1`;
        }
        if (!(inverse_test_type in seen_values_map)) {
          seen_values_map[test_type] = value;
        } else {
          if (seen_values_map[inverse_test_type] > value) {
            _errors[index]["value"] =
              `Value must be greater than 0 ${capitalizeFirstLetters(
                inverse_test_type,
              )}`;
          }
        }
      }
    }
    if (Object.keys(_errors[index]).length) {
      valid = false;
    }
  });
  setErrors(_errors);
  return valid;
};

export const quickFilter = (obj, accessors, filterValue) => {
  if (!filterValue) return true;

  return accessors.some((key) => {
    let value;
    if (typeof key === "function") {
      value = key(obj);
    } else {
      value = obj[key];

      if (key.includes(".")) {
        value = accessProperty(key, obj);
      }
    }
    return value?.toLowerCase().includes(filterValue.toLowerCase());
  });
};

export const autoColumnWidth = (data, accessor, headerText, _maxWidth) => {
  const maxWidth = _maxWidth || 400;

  if (!data) return maxWidth;

  const textWidths = data.map((row) => {
    let textValue = "";
    if (row?.[accessor]) {
      textValue = `${row[accessor]}`;
    }

    if (accessor?.includes(".")) {
      textValue = accessProperty(accessor, row) || "";
    }

    return getStringWidth(textValue);
  });

  const width = Math.max(...textWidths, getStringWidth(headerText));

  return Math.min(maxWidth, width);
};

export const getTableRelativePath = (
  dataModelReference,
  relativeIdentifier,
) => {
  const splitDataModel = dataModelReference.split(":");
  let version = null;
  if (splitDataModel[1] !== "latest") {
    version = splitDataModel[1];
  }
  const splitReference = relativeIdentifier.split("|");
  const relativePath = {
    dataModel: splitDataModel[0],
    domain: splitReference[0],
    subjectArea: splitReference[1],
    table: splitReference[2],
  };
  return { relativePath, version };
};

export const buildTableUiUrl = (relativePath, version) =>
  `/data-hub/data-model/catalog-overview/${relativePath.dataModel}/${
    relativePath.domain
  }/${relativePath.subjectArea}/${relativePath.table}${
    version ? `?version=${version}` : ""
  }`;

export const buildTableApiUrl = (relativePath, version) =>
  `/data_model/${relativePath.dataModel}/domains/${
    relativePath.domain
  }/subject_areas/${relativePath.subjectArea}/tables/${relativePath.table}${
    version ? `?version=${version}` : ""
  }`;

/**
 * Retrieves a nested value from an object based on the given path.
 * @param {Object} object - The object to traverse.
 * @param {string} path - The path to the desired value, using dot notation (e.g., 'thing.thing2.thing3').
 * @returns {*} The retrieved value if found, or undefined if the path is invalid.
 */
export const getValueByPath = (object, path) => {
  const keys = path.split(".");

  for (const key of keys) {
    if (object && key in object) {
      object = object[key];
    } else {
      return undefined;
    }
  }

  return object;
};

export const getVersionText = (version, version_status, version_name) => {
  let versionText = version_name || `v.${version}`;

  if (version_status !== "archived") {
    versionText = `${versionText} - ${capitalizeOnlyFirstLetter(
      version_status,
    )}`;
  } else {
    versionText = `${versionText} - Past`;
  }

  return versionText;
};
