import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import "highcharts/modules/funnel";
import HighchartsReact from "highcharts-react-official";
import { styled } from "styled-components";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

type FunnelChartProps = {
  title: string;
  data: {
    name: string;
    amount: number;
  }[];
  dataLabel?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
};

const FunnelChartWrapper = styled(Wrapper)`
  min-height: 147px;
  min-width: 251px;
  max-width: 629px;
`;

const FunnelChart = memo(
  ({ title, data, dataLabel, toolTip, loading, error }: FunnelChartProps) => {
    let totalAmount: number | null = null;
    data.forEach((o) => {
      if (o.amount) {
        if (totalAmount === null) totalAmount = 0;
        totalAmount += o.amount;
      }
    });

    const seriesData: Highcharts.PointOptionsObject[] = data.map((item) => [
      item.name,
      item.amount,
    ]);

    const renderFunnelChart = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      const options: Highcharts.Options = {
        chart: {
          type: "funnel",
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b> ({point.y:,.0f})",
              softConnector: true,
            },
            center: ["40%", "50%"],
            neckWidth: "30%",
            neckHeight: "25%",
            width: "80%",
          },
        } as Highcharts.PlotOptions,
        legend: {
          enabled: true,
          align: "left",
        },
        series: [
          {
            name: dataLabel ?? "",
            data: seriesData,
          },
        ] as Highcharts.SeriesOptionsType[],
        tooltip: {
          formatter: function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          },
        },
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      return <HighchartsReact highcharts={Highcharts} options={options} />;
    };

    return (
      <FunnelChartWrapper>
        <Header title={title} toolTip={toolTip} />
        {renderFunnelChart()}
      </FunnelChartWrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default FunnelChart;
