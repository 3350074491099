import React from "react";

import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const Label = styled.label`
  border: 1px solid ${colorTheme("primary")};
  background: ${colorTheme("primary")};
  color: ${colorTheme("white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
  border-width: 3px;
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  height: 32px;
  opacity: ${(props) => (props.disabled ? ".75" : "1")};
  width: 115px;
  &:hover {
    cursor: ${(props) =>
      props.disabled === "disabled" ? "not-allowed" : "pointer"};
    background: ${colorTheme("primaryD2")};
    border-color: ${colorTheme("primaryD2")};
  }
`;

function Upload({ onChange, text, style, accept, icon }) {
  const key = Math.random(0, 10);
  return (
    <React.Fragment>
      <input
        accept={accept}
        style={{ display: "none" }}
        id={key}
        type="file"
        onChange={onChange}
      />
      <Label style={style} htmlFor={key}>
        {icon && (
          <i className={`fa-regular ${icon}`} style={{ marginRight: 5 }} />
        )}
        {text}
      </Label>
    </React.Fragment>
  );
}

Upload.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string,
  accept: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object,
};

export default Upload;
