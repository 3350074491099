import React from "react";

import { BreadCrumbsV2 } from "@commonComponents";
import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { useParams } from "react-router";
import { styled } from "styled-components";

import LEAP_INSIGHTS_URL from "../../urls";
import type { ListAnalyticDomain, ListInsightsDashboard } from "~/src/types";
import { colorTheme } from "~/src/utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colorTheme("neutralL6")};
  padding: ${gridSpacing[5]}px;
  border-bottom: 1px solid ${colorTheme("neutralL4")};
`;

type TopBarProps = {
  insightsDashboards: ListInsightsDashboard[];
  subjects: ListAnalyticDomain[];
  setSelectedObject: (value: string) => void;
};

const TopBar = ({
  insightsDashboards,
  subjects,
  setSelectedObject,
}: TopBarProps) => {
  const { subjectPath, groupPath, dashboardPath } = useParams<{
    subjectPath: string;
    groupPath: string;
    dashboardPath: string;
  }>();
  const subject = subjects.find((o) => o.path === subjectPath);
  const group = subject?.subdomains.find((o) => o.path === groupPath);
  const dashboard = insightsDashboards.find(
    (o) => o.identifier === dashboardPath && o.subdomain.uuid === group?.uuid,
  );

  const getRoutes = () => {
    const routes = [];

    if (!subject) {
      return [];
    }

    routes.push({
      icon: `${subject.icon}`,
      iconStyle: {
        fontSize: 18,
        color: colorTheme("neutral"),
      },
      breadcrumb: subject.name,
      path: `${LEAP_INSIGHTS_URL.baseUrl}/${subject.path}`,
    });

    if (group) {
      routes.push({
        icon: "fa-regular fa-folder",
        iconStyle: {
          color: colorTheme("neutralL1"),
        },
        breadcrumb: group.name,
        path: `${LEAP_INSIGHTS_URL.baseUrl}/${subject.path}/${group.path}`,
      });

      if (dashboard) {
        routes.push({
          icon: "fa-regular fa-table-layout",
          iconStyle: {
            color: colorTheme("neutralL1"),
          },
          breadcrumb: dashboard.name,
          path: `${LEAP_INSIGHTS_URL.baseUrl}/${subject.path}/${group.path}/${dashboard.identifier}`,
        });
      }
    }

    return routes;
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <BreadCrumbsV2
          baseRoute={{
            icon: "fa-kit fa-hdp-app-insights-portal",
            iconStyle: {
              color: colorTheme("neutral"),
              fontSize: 18,
            },
            breadcrumb: "Leap Insights",
            path: `${LEAP_INSIGHTS_URL.baseUrl}#all-published-dashboards`,
            onClick: () => setSelectedObject(""),
          }}
          routes={getRoutes()}
        />
      </div>
    </Container>
  );
};

export default TopBar;
