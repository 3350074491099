const baseUrl = "/consumer-360";

export default {
  baseUrl,
  CONFIGURATION_SOURCES: `${baseUrl}/configuration/sources`,
  CONFIGURATION_GOLDEN_RECORD: `${baseUrl}/configuration/golden-record`,
  CONFIGURATION_FIELD_VALIDATOR: `${baseUrl}/configuration/field-validator`,
  CONFIGURATION_ENTITY_UNIFICATION: `${baseUrl}/configuration/entity-unification`,
  CONSUMER_PROFILES: `${baseUrl}/consumers/profiles`,
  CONSUMER_REGISTRY: `${baseUrl}/consumers/registry`,
  OPERATIONS_MONITORING: `${baseUrl}/operations/monitoring`,
  SURVEY_OUTPUT_MAPPING_SURVEYS: `${baseUrl}/survey/output-mapping/surveys`,
  SURVEY_TARGET_MODEL_MANAGEMENT: `${baseUrl}/survey/target-model-management`,
};
