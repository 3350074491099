import React, { useContext } from "react";

import { DataViz } from "@commonComponents";

import {
  marketBreakdownUnassignedQuery,
  overallAssignedQuery,
  overallUnassignedQuery,
  payerBreakdownUnassignedQuery,
  seenFunnelQuery1,
  unassignedByPanelQuery,
  unassignedDemographicsQuery,
  unassignedDimensions,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { getStackedBarChartData } from "../Common/attributionCommon";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const UnassignedBreakdown = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: unassignedDimensions,
  });

  const {
    resultSet: overallAssignedResult,
    error: overallAssignedError,
    isLoading: overallAssignedLoading,
  } = useCubeApiQuery(overallAssignedQuery(filters, filterOrder));

  const {
    resultSet: overallUnassignedResult,
    error: overallUnassignedError,
    isLoading: overallUnassignedLoading,
  } = useCubeApiQuery(overallUnassignedQuery(filters, filterOrder));

  const {
    resultSet: unassignedByPanelResult,
    error: unassignedByPanelError,
    isLoading: unassignedByPanelLoading,
  } = useCubeApiQuery(unassignedByPanelQuery(filters, filterOrder));

  const {
    resultSet: unassignedDemographicsResult,
    error: unassignedDemographicsError,
    isLoading: unassignedDemographicsLoading,
  } = useCubeApiQuery(unassignedDemographicsQuery(filters, filterOrder));

  const {
    resultSet: seenFunnelResult1,
    error: seenFunnelError1,
    isLoading: seenFunnelLoading1,
  } = useCubeApiQuery(seenFunnelQuery1(filters, filterOrder));

  const {
    resultSet: payerBreakdownUnassignedResult,
    error: payerBreakdownUnassignedError,
    isLoading: payerBreakdownUnassignedLoading,
  } = useCubeApiQuery(payerBreakdownUnassignedQuery(filters, filterOrder));

  const {
    resultSet: marketBreakdownUnassignedResult,
    error: marketBreakdownUnassignedError,
    isLoading: marketBreakdownUnassignedLoading,
  } = useCubeApiQuery(marketBreakdownUnassignedQuery(filters, filterOrder));

  const overallAssignedData =
    overallAssignedResult?.rawData()[0][
      "assigned_consumer_detail.total_luc_id_count"
    ];

  const overallUnassignedData =
    overallUnassignedResult?.rawData()[0][
      "unassigned_consumer_detail.total_luc_id_count"
    ];

  const marketBreakdownUnassignedData =
    marketBreakdownUnassignedResult?.rawData();

  const payerBreakdownUnassignedData =
    payerBreakdownUnassignedResult?.rawData();

  const unassignedByPanelData = unassignedByPanelResult
    ?.rawData()
    .sort((a, b) =>
      a["unassigned_consumer_detail.panel_name"].localeCompare(
        b["unassigned_consumer_detail.panel_name"],
      ),
    );

  const getUnassignedByPanelData = () => {
    const panelData = unassignedByPanelData ?? [];
    const seenSeries = panelData.map((obj) =>
      parseInt(
        obj["appointment_completed.any_provider_total_luc_id_count_540_days"] ??
          "",
      ),
    );
    const notSeenSeries = panelData.map(
      (obj) =>
        parseInt(obj["unassigned_consumer_detail.total_luc_id_count"] ?? "") -
        parseInt(
          obj[
            "appointment_completed.any_provider_total_luc_id_count_540_days"
          ] ?? "",
        ),
    );
    const panelNames = panelData.map(
      (obj) => obj["unassigned_consumer_detail.panel_name"] ?? "",
    );

    return {
      options: panelNames,
      data: [
        {
          name: "Seen",
          data: seenSeries,
        },
        {
          name: "Not Seen",
          data: notSeenSeries,
        },
      ],
    };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.PieChart
          title="Unassigned Consumers"
          toolTip="Count of unassigned individuals as a percentage of total consumers"
          data={[
            {
              amount: parseInt(overallAssignedData ?? ""),
              name: "Assigned",
            },
            {
              amount: parseInt(overallUnassignedData ?? ""),
              name: "Unassigned",
            },
          ]}
          error={
            (overallUnassignedError || overallAssignedError) &&
            "Something went wrong"
          }
          loading={overallUnassignedLoading || overallAssignedLoading}
          label="Members"
        />
        <DataViz.FunnelChart
          title="Unassigned Seen Funnel"
          toolTip="Breakdown of seen and unseen individuals within the unassigned population"
          dataLabel="Members"
          data={[
            {
              name: "Unassigned",
              amount: parseInt(
                seenFunnelResult1?.rawData()[0][
                  "unassigned_consumer_detail.total_luc_id_count"
                ] ?? "",
              ),
            },
            {
              name: "Not Seen",
              amount:
                parseInt(
                  seenFunnelResult1?.rawData()[0][
                    "unassigned_consumer_detail.total_luc_id_count"
                  ] ?? "",
                ) -
                parseInt(
                  seenFunnelResult1?.rawData()[0][
                    "appointment_completed.any_provider_total_luc_id_count_540_days"
                  ] ?? "",
                ),
            },
            {
              name: "Seen",
              amount: parseInt(
                seenFunnelResult1?.rawData()[0][
                  "appointment_completed.any_provider_total_luc_id_count_540_days"
                ] ?? "",
              ),
            },
          ]}
          loading={seenFunnelLoading1}
          error={seenFunnelError1 && "Something went wrong"}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Unassigned by Panel"
          toolTip="Count of individuals that are qualified and unassigned by panel"
          yAxisLabel="Count"
          type="stacked"
          {...getUnassignedByPanelData()}
          error={unassignedByPanelError && "Something went wrong"}
          loading={unassignedByPanelLoading}
          formatter={function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          }}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.PieChart
          title="Payer Breakdown"
          toolTip="Payer breakdown of unassigned individuals"
          data={
            payerBreakdownUnassignedData?.map((obj) => {
              return {
                amount: parseInt(
                  obj["unassigned_consumer_detail.total_luc_id_count"],
                ),
                name: obj["roster_active.payer_name"] ?? "Unknown",
              };
            }) ?? []
          }
          error={payerBreakdownUnassignedError && "Something went wrong"}
          loading={payerBreakdownUnassignedLoading}
          label="Members"
        />
        <DataViz.PieChart
          title="Market Segment Breakdown"
          toolTip="Market segment breakdown of unassigned individuals"
          data={
            marketBreakdownUnassignedData?.map((obj) => {
              return {
                amount: parseInt(
                  obj["unassigned_consumer_detail.total_luc_id_count"],
                ),
                name: obj["roster_active.market_segment"] ?? "Unknown",
              };
            }) ?? []
          }
          error={marketBreakdownUnassignedError && "Something went wrong"}
          loading={marketBreakdownUnassignedLoading}
          label="Members"
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Unassigned Age And Gender Distribution"
          toolTip="Age and gender breakdown of unassigned individuals"
          loading={unassignedDemographicsLoading}
          error={unassignedDemographicsError && "Something went wrong"}
          type="stacked"
          yAxisLabel="Consumers"
          {...getStackedBarChartData<typeof unassignedDemographicsQuery>(
            unassignedDemographicsResult
              ?.rawData()
              .filter(
                (obj) =>
                  obj["unassigned_consumer_detail.age_category"] &&
                  obj["unassigned_consumer_detail.gender"] &&
                  obj["unassigned_consumer_detail.total_luc_id_count"],
              )
              .sort((a, b) => {
                const aNum = parseInt(
                  a["unassigned_consumer_detail.age_category"].split("-")[0],
                );
                const bNum = parseInt(
                  b["unassigned_consumer_detail.age_category"].split("-")[0],
                );

                return aNum > bNum ? 1 : aNum < bNum ? -1 : 0;
              }) ?? [],
            "unassigned_consumer_detail.age_category",
            "unassigned_consumer_detail.gender",
            "unassigned_consumer_detail.total_luc_id_count",
            "number",
          )}
          formatter={function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          }}
        />
      </DataViz.Row>
    </>
  );
};

export default UnassignedBreakdown;
