import React, { memo } from "react";

import { GraphicCallout, gridSpacing } from "@lucernahealth/lucerna-health-ui";
import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import "highcharts/highcharts-more.js";
import "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { styled } from "styled-components";

import { colorTheme, numberWithCommas } from "@utils";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

type GaugeProps = {
  title: string;
  numerator: number;
  denominator: number;
  numeratorTitle: string;
  denominatorTitle: string;
  toolTip?: string;
  error?: string | null;
  loading?: boolean;
  small?: boolean;
  missing?: boolean;
  missingMessage?: string;
};

const GaugeWrapper = styled(Wrapper)`
  min-height: 147px;
  min-width: 251px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: ${gridSpacing[5]}px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const FooterTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colorTheme("neutralL1")};
  margin-bottom: ${gridSpacing[2]}px;
`;

const FooterNumber = styled.p<{ small?: boolean }>`
  font-family: Manrope;
  font-size: ${({ small }) => (small ? "24px" : "36px")};
  font-style: normal;
  font-weight: 700;
`;

const Gauge = memo(
  ({
    missing,
    missingMessage,
    title,
    numerator,
    denominator,
    numeratorTitle,
    denominatorTitle,
    toolTip,
    error,
    loading,
    small,
  }: GaugeProps) => {
    const width = small ? 200 : 300;
    const height = small ? 200 : 300;

    const renderGauge = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      const gaugeOptions: Highcharts.Options = {
        chart: {
          type: "solidgauge",
          height,
          width,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
        },
        credits: {
          enabled: false,
        },
        title: undefined,
        pane: {
          size: "100%",
          startAngle: -90,
          endAngle: 90,
          background: [
            {
              backgroundColor: colorTheme("neutralL5"),
              innerRadius: "80%",
              outerRadius: "100%",
              shape: "arc",
              borderWidth: 0,
            },
          ],
        },
        exporting: {
          enabled: true,
        },
        yAxis: {
          min: 0,
          max: Math.floor(denominator),
          stops: [
            [0.1, colorTheme("success")],
            [0.6, colorTheme("warning")],
            [0.9, colorTheme("danger")],
          ],
          lineWidth: 0,
          minorTicks: false,
          tickLength: 0,
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        series: [
          {
            data: [numerator],
            enableMouseTracking: false,
            dataLabels: {
              y: -70,
              format:
                `<div style="text-align:center; font-size: ${small ? "24px" : "36px"}; ${small ? "margin-top: 16px" : ""}">` +
                `${Math.round((numerator / denominator) * 100)}%` +
                "</div>",
            },
            innerRadius: "80%",
          },
        ] as Highcharts.SeriesSolidgaugeOptions[],
      };

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: height / 2,
            overflow: "hidden",
          }}
        >
          <HighchartsReact highcharts={Highcharts} options={gaugeOptions} />
        </div>
      );
    };

    if (missing) {
      return (
        <GaugeWrapper>
          <Header title={title} toolTip={toolTip} />
          <GraphicCallout
            headerStyle={{
              fontSize: 16,
            }}
            imgStyle={{
              width: 120,
              height: 120,
            }}
            subheaderStyle={{
              fontSize: 12,
            }}
            empty="search"
            subheader={missingMessage ?? " "}
          />
        </GaugeWrapper>
      );
    }

    return (
      <GaugeWrapper>
        <Header title={title} toolTip={toolTip} />
        {renderGauge()}
        {!loading && !error && (
          <Footer>
            <div>
              <FooterTitle>{numeratorTitle}</FooterTitle>
              <FooterNumber small={small}>
                {numberWithCommas(numerator)}
              </FooterNumber>
            </div>
            <div>
              <FooterTitle>{denominatorTitle}</FooterTitle>
              <FooterNumber
                small={small}
                style={{ color: colorTheme("neutralL1") }}
              >
                {numberWithCommas(denominator)}
              </FooterNumber>
            </div>
          </Footer>
        )}
      </GaugeWrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default Gauge;
