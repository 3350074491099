import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";

import { Button, Tabs, gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { LEAP_INSIGHTS_URLS } from "@urls";
import axios from "axios";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import AllPublishedDashboardsTab from "./AllPublishedDashboardsTab";
import FavoritesTab from "./FavoritesTab";
import type { FilterProps } from "./helpers";
import QuicksightDashboardsTab from "./QuicksightDashboardsTab";
import RecentsTab from "./RecentsTab";
import type { QuicksightProps } from "../helpers";
import { DocumentationButton } from "~/src/CommonComponents";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { checkPermissions } from "~/src/utils";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

type PortalHomeProps = {
  userTableView: boolean;
  insightsDashboards: ListInsightsDashboard[];
  setSelectedDashboard: (dashboard: ListInsightsDashboard) => void;
  insightsDashboardsLoading: boolean;
  allQuicksightDashboards: QuicksightProps[];
  allQuicksightDashboardsLoading: boolean;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  favoritesData: UserFavorites[];
  getFavorites: () => void;
  subjects: ListAnalyticDomain[];
};

type PortalContextProps = {
  filters: AdvFilterProps;
  setFilters: Dispatch<SetStateAction<object>>;
  filterOptions: object;
  setFilterOptions: Dispatch<SetStateAction<object>>;
};

export const PortalContext = React.createContext<PortalContextProps>({
  filters: {},
  setFilters: () => {},
  filterOptions: {},
  setFilterOptions: () => {},
});

const PortalHome = ({
  userTableView,
  insightsDashboards,
  setSelectedDashboard,
  insightsDashboardsLoading,
  allQuicksightDashboards,
  allQuicksightDashboardsLoading,
  currentTab,
  setCurrentTab,
  favoritesData,
  getFavorites,
  subjects,
}: PortalHomeProps) => {
  const navigate = useNavigate();

  const [tableView, setTableView] = useState(userTableView);
  const [recentDashboards, setRecentDashboards] = useState<
    ListInsightsDashboard[]
  >([]);
  const [recentDashboardsLoading, setRecentDashboardsLoading] = useState(true);
  const [recentDashboardError, setRecentDashboardError] = useState("");

  const [filters, setFilters] = useState<FilterProps>({
    type: "",
    search: "",
    analytic_domain: "",
    subdomain: [],
    managed_by: "",
  });

  useEffect(() => {
    if (!insightsDashboardsLoading) {
      const params = new URLSearchParams();
      params.append("object_type", "Leap Dashboard (Customer)");
      params.append("object_type", "Leap Dashboard (Lucerna)");

      axios
        .get(`/monitoring/page_view/me?${params.toString()}`)
        .then((res) => {
          setRecentDashboardsLoading(false);

          const foundDashboards = res.data.result
            .map(({ object_pk }: { object_pk: string }) => {
              const foundDashboard = insightsDashboards.find(
                (d) => d.uuid === object_pk,
              );
              if (foundDashboard) {
                return foundDashboard;
              }
            })
            .filter((o: ListInsightsDashboard | undefined) => o)
            .reduce(
              (
                unique: ListInsightsDashboard[],
                dashboard: ListInsightsDashboard,
              ) => {
                if (!unique.some((item) => item.uuid === dashboard.uuid)) {
                  unique.push(dashboard);
                }
                return unique;
              },
              [],
            )
            .slice(0, 15);

          setRecentDashboards(foundDashboards);
        })
        .catch((err) => {
          setRecentDashboardsLoading(false);
          setRecentDashboardError(
            "Something went wrong fetching recent dashboards",
          );
          console.error(err);
        });
    }
  }, [insightsDashboards, insightsDashboardsLoading]);

  return (
    <div
      style={{
        width: "100%",
        padding: gridSpacing[4],
        overflowX: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2
            style={{
              paddingTop: gridSpacing[3],
              paddingBottom: gridSpacing[3],
            }}
          >
            Leap Insights
          </h2>
          <DocumentationButton docRoute={LEAP_INSIGHTS_URLS.baseUrl} />
        </div>
        {checkPermissions("insights.view_dashboard_settings") &&
          checkPermissions("quicksight.view_dashboard") && (
            <Button
              style={{ marginRight: gridSpacing[3] }}
              type="secondary"
              icon="fa-regular fa-gear"
              onClick={() =>
                navigate(`${LEAP_INSIGHTS_URLS.baseUrl}/dashboard-settings`)
              }
              tooltip="Dashboard Settings"
            />
          )}
      </div>

      <Tabs
        includeHash
        currentTab={currentTab}
        onSelect={setCurrentTab}
        tabContentStyle={{ paddingTop: 0 }}
      >
        <Tabs.Content
          tabKey="all-published-dashboards"
          title="All Published Dashboards"
        >
          <AllPublishedDashboardsTab
            filters={filters}
            setFilters={setFilters}
            tableView={tableView}
            setTableView={setTableView}
            insightsDashboards={insightsDashboards}
            insightsDashboardsLoading={insightsDashboardsLoading}
            setSelectedDashboard={setSelectedDashboard}
            favoritesData={favoritesData}
            getFavorites={getFavorites}
            subjects={subjects}
          />
        </Tabs.Content>
        <Tabs.Content tabKey="recents" title="Recents">
          <RecentsTab
            filters={filters}
            setFilters={setFilters}
            tableView={tableView}
            setTableView={setTableView}
            recentDashboards={recentDashboards}
            setSelectedDashboard={setSelectedDashboard}
            insightsDashboardsLoading={insightsDashboardsLoading}
            recentDashboardsLoading={recentDashboardsLoading}
            recentDashboardError={recentDashboardError}
            favoritesData={favoritesData}
            getFavorites={getFavorites}
            subjects={subjects}
          />
        </Tabs.Content>
        <Tabs.Content tabKey="favorites" title="Favorites">
          <FavoritesTab
            filters={filters}
            setFilters={setFilters}
            tableView={tableView}
            setTableView={setTableView}
            setSelectedDashboard={setSelectedDashboard}
            insightsDashboards={insightsDashboards}
            favoritesData={favoritesData}
            getFavorites={getFavorites}
            subjects={subjects}
          />
        </Tabs.Content>
        {checkPermissions("insights.view_all_quicksight_dashboards") && (
          <Tabs.Content
            tabKey="quicksight-dashboards"
            title="All Quicksight Dashboards"
          >
            <QuicksightDashboardsTab
              filters={filters}
              setFilters={setFilters}
              tableView={tableView}
              setTableView={setTableView}
              allQuicksightDashboardsLoading={allQuicksightDashboardsLoading}
              allQuicksightDashboards={allQuicksightDashboards}
              favoritesData={favoritesData}
              getFavorites={getFavorites}
            />
          </Tabs.Content>
        )}
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        leapInsightsTableView: boolean;
      };
    };
  };
}) => {
  return {
    userTableView: state.main.user.metadata?.leapInsightsTableView,
  };
};

export default connect(mapStateToProps)(PortalHome);
