import Swal from "sweetalert2";

import { colorTheme } from "@utils";

interface SweetAlertProps {
  title?: string;
  text?: string;
  showCancelButton?: boolean;
  isWarning?: boolean;
  success?: () => void;
  dismiss?: () => void;
  action?: () => void;
}

const SweetAlert = ({
  title,
  text,
  showCancelButton,
  isWarning,
  success,
  dismiss,
  action,
}: SweetAlertProps) =>
  Swal.fire({
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
    confirmButtonColor: colorTheme(isWarning ? "danger" : "primary"),
    reverseButtons: true,
    showCancelButton,
    title,
    text,
  }).then((result) => {
    if (action) {
      action();
    }

    if (result.value && success) {
      success();
    } else if (!result.value && dismiss) {
      dismiss();
    }

    return result;
  });

export default SweetAlert;
