import SYSTEM_URLS from "./urls";

export default [
  {
    appTitle: true,
    header: "System",
    icon: "fa-kit fa-hdp-app-settings",
    baseRoute: "system",
  },
  {
    header: "User Management",
    children: [
      {
        name: "Users",
        route: SYSTEM_URLS.USER_MANAGEMENT_USERS,
        permissions: ["accounts.view_user"],
      },
      {
        name: "Teams",
        route: SYSTEM_URLS.USER_MANAGEMENT_GROUPS,
        permissions: ["accounts.view_groupextend"],
      },
      {
        name: "Quicksight Groups",
        route: SYSTEM_URLS.USER_MANAGEMENT_QUICKSIGHT_GROUPS,
        permissions: ["accounts.view_groupextend"],
      },
      {
        name: "Token Management",
        route: SYSTEM_URLS.TOKEN_MANAGEMENT,
        permissions: ["knox.view_authtoken"],
      },
    ],
  },

  {
    header: "Documentation",
    children: [
      {
        name: "Account",
        route: SYSTEM_URLS.DOCUMENTATION_ACCOUNT,
        permissions: ["accounts.view_appsetting", "accounts.view_apptheme"],
      },
      {
        name: "Overview",
        route: SYSTEM_URLS.DOCUMENTATION_OVERVIEW,
        permissions: null,
      },
      {
        name: "Internal API",
        route: SYSTEM_URLS.DOCUMENTATION_API,
        permissions: null,
        superUserOnly: true,
      },
      {
        name: "Public API",
        route: SYSTEM_URLS.PUBLIC_DOCUMENTATION_API,
        permissions: ["accounts.public_api_docs_access"],
      },
    ],
  },
];
