import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

export type ScatterChartProps = {
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  xAxisUnit: string;
  yAxisUnit: string;
  data: {
    values: number[][];
    symbol: "circle" | "square" | "diamond" | "triangle" | "triangle-down";
    name: string;
  }[];
  linkUrl?: string;
  linkText?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
};

const ScatterChart = memo(
  ({
    title,
    xAxisLabel,
    yAxisLabel,
    xAxisUnit,
    yAxisUnit,
    data,
    linkUrl,
    linkText,
    toolTip,
    loading,
    error,
  }: ScatterChartProps) => {
    const seriesData = data.map((item) => {
      return {
        name: item.name,
        id: item.name,
        data: item.values,
        marker: {
          symbol: item.symbol,
        },
      };
    });

    const renderScatterChart = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      const scatterOptions: Highcharts.Options = {
        chart: {
          type: "scatter",
        },
        xAxis: {
          title: {
            text: xAxisLabel,
          },
          labels: {
            format: `{value} ${xAxisUnit ?? ""}`,
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
        },
        yAxis: {
          title: {
            text: yAxisLabel,
          },
          labels: {
            format: `{value} ${yAxisUnit ?? ""}`,
          },
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 2.5,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
            jitter: {
              x: 0.005,
            },
          },
        },
        series: seriesData as Highcharts.SeriesOptionsType[],
        legend: {
          layout: "horizontal",
          align: "left",
        },
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      return (
        <HighchartsReact highcharts={Highcharts} options={scatterOptions} />
      );
    };

    return (
      <Wrapper>
        <Header
          title={title}
          toolTip={toolTip}
          linkUrl={linkUrl}
          linkText={linkText}
        />
        {renderScatterChart()}
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default ScatterChart;
