import type { FC } from "react";

import ActivityAnalysis from "./ActivityAnalysis/ActivityAnalysis";
import AppointmentAnalysisSummaryAdvancedFilters from "./AppointmentAnalysisSummary/advancedFilters";
import AppointmentAnalysisSummary from "./AppointmentAnalysisSummary/AppointmentAnalysisSummary";
import AppointmentTrendFacilityProviders from "./AppointmentTrendFacilityProviders/AppointmentTrendFacilityProviders";
import AppointmentTrendPatientVisitTypeAnalysis from "./AppointmentTrendPatientVisitTypeAnalysis/AppointmentTrendPatientVisitTypeAnalysis";
import AppointmentTrendSummary from "./AppointmentTrendSummary/AppointmentTrendSummary";
import CapacityAnalysis from "./CapacityAnalysis/CapacityAnalysis";
import { appointmentFilterOrder } from "./Common/appointmentCommon";
import { assignedDrillDownAdvFilters } from "./Common/attributionCommon";
import ConsumerDemographicsAdvancedFilters from "./ConsumerDemographics/advancedFilters";
import ConsumerDemographics from "./ConsumerDemographics/ConsumerDemographics";
import CpsDataQualityAdvancedFilters from "./CpsDataQuality/advancedFilters";
import CpsDataQuality from "./CpsDataQuality/CpsDataQuality";
import CpsOperations from "./CpsOperations/CpsOperations";
import DashboardAnalysisAdvancedFilters from "./DashboardAnalysis/advancedFilters";
import DashboardAnalysis from "./DashboardAnalysis/DashboardAnalysis";
import FacilityAnalysis from "./FacilityAnalysis/FacilityAnalysis";
import PayerBreakdownAdvancedFilters from "./PayerBreakdown/advancedFilters";
import PayerBreakdown from "./PayerBreakdown/PayerBreakdown";
import ProviderAnalysis from "./ProviderAnalysis/ProviderAnalysis";
import RosterPayerBreakdownAdancedFilters from "./RosterPayerBreakdown/advancedFilters";
import RosterPayerBreakdown from "./RosterPayerBreakdown/RosterPayerBreakdown";
import RosterSummaryAdvancedFilters from "./RosterSummary/advancedFilters";
import RosterSummary from "./RosterSummary/RosterSummary";
import UnassignedBreakdownAdvancedFilters from "./UnassignedBreakdown/advancedFilters";
import UnassignedBreakdown from "./UnassignedBreakdown/UnassignedBreakdown";
import UserGroupAnalysisAdvancedFilters from "./UserGroupAnalysis/advancedFilters";
import UserGroupAnalysis from "./UserGroupAnalysis/UserGroupAnalysis";
import VisitsAnalysisAdvancedFilters from "./VisitAnalysis/advancedFilters";
import VisitsAnalysis from "./VisitAnalysis/VisitsAnalysis";
import FacilityScheduleAnalysisAdvancedFilters from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/FacilityScheduleAnalysis/advancedFilters";
import FacilityScheduleAnalysis from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/FacilityScheduleAnalysis/FacilityScheduleAnalysis";
import ProviderScheduleAnalysisAdvancedFilters from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/ProviderScheduleAnalysis/advancedFilters";
import ProviderScheduleAnalysis from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/ProviderScheduleAnalysis/ProviderScheduleAnalysis";

interface FilterItem {
  label: string;
  isParent?: boolean;
  id?: string;
  cubeKey?: string;
  parentId?: string;
}

type DashboardComponent = {
  component: FC;
  advancedFilters?: FilterItem[];
  hideAdvancedFilters?: boolean;
};

type DashboardComponentsType = Record<string, DashboardComponent>;

/* 
  ADDING A NEW DASHBOARD
  1. Follow backend instructions to create a new dashboard in the database 
    https://www.notion.so/lucerna-health/Dashboard-Management-Instructions-d3e2ac204ac847a4b10c29c0339526ba?pvs=4
  2. Create a new folder for the component, its queries, and advanced filters in the src/App/LeapInsights/Components/Dashboards folder
  3. Use the UUID defined in the backend JSON object to map to the corresponding component in the DashboardComponents object below
*/
export const DashboardComponents: DashboardComponentsType = {
  "00bdcfe8-686d-4ab7-9029-2614a0c7bfcd": {
    component: CpsDataQuality,
    advancedFilters: CpsDataQualityAdvancedFilters,
  },
  "00c10f70-5f3d-45b0-849f-e2cf196f64e1": {
    component: CpsOperations,
    hideAdvancedFilters: true,
  },
  "e3e9da92-e60e-4896-89a7-cd8b42856b83": {
    component: AppointmentTrendFacilityProviders,
    advancedFilters: appointmentFilterOrder,
  },
  "6bdf790f-ed22-4e0d-b99f-4c1ef9f3d681": {
    component: AppointmentTrendSummary,
    advancedFilters: appointmentFilterOrder,
  },
  "00e8fb2c-7047-478d-bb85-08b227dfb837": {
    component: AppointmentTrendPatientVisitTypeAnalysis,
    advancedFilters: appointmentFilterOrder,
  },
  "250ebf53-32cc-4145-8d2e-a4486f96b31b": {
    component: RosterPayerBreakdown,
    advancedFilters: RosterPayerBreakdownAdancedFilters,
  },
  "4f30fb42-9146-4d95-8799-a61b0534c9f9": {
    component: RosterSummary,
    advancedFilters: RosterSummaryAdvancedFilters,
  },
  "b8f2a953-c624-494e-a13e-f06453c44b62": {
    component: ConsumerDemographics,
    advancedFilters: ConsumerDemographicsAdvancedFilters,
  },
  "84692d77-158c-4ee2-a54d-d4c72ab954a0": {
    component: AppointmentAnalysisSummary,
    advancedFilters: AppointmentAnalysisSummaryAdvancedFilters,
  },
  "849bde4c-6dd4-4fec-9f2b-fc46e08065ce": {
    component: CapacityAnalysis,
    advancedFilters: assignedDrillDownAdvFilters,
  },
  "2496a888-ac96-47f3-b279-8c12b078f2ff": {
    component: FacilityAnalysis,
    advancedFilters: assignedDrillDownAdvFilters,
  },
  "7c4fddaf-d5eb-47d8-95b2-aa4cf496ff7d": {
    component: PayerBreakdown,
    advancedFilters: PayerBreakdownAdvancedFilters,
  },
  "842ac07a-9098-4d90-8493-c2938e20f304": {
    component: ProviderAnalysis,
    advancedFilters: assignedDrillDownAdvFilters,
  },
  "f9950608-397d-473f-bb41-29b74c102c45": {
    component: UnassignedBreakdown,
    advancedFilters: UnassignedBreakdownAdvancedFilters,
  },
  "823fe250-cd7f-4239-9827-f619b83f6c74": {
    component: ActivityAnalysis,
    hideAdvancedFilters: true,
  },
  "51a2319e-3f8d-4062-bc15-3d0d274efa11": {
    component: DashboardAnalysis,
    advancedFilters: DashboardAnalysisAdvancedFilters,
  },
  "b6ed0f2a-ac50-4ca1-8145-b6b9c67ce6c6": {
    component: UserGroupAnalysis,
    advancedFilters: UserGroupAnalysisAdvancedFilters,
  },
  "d3896144-23d1-4b4c-b501-15ed286afeed": {
    component: VisitsAnalysis,
    advancedFilters: VisitsAnalysisAdvancedFilters,
  },
  "823f0250-cd7f-4239-9827-f619b83f6c74": {
    component: FacilityScheduleAnalysis,
    advancedFilters: FacilityScheduleAnalysisAdvancedFilters,
  },
  "823f0250-cd7f-4239-9827-f612b83c6c74": {
    component: ProviderScheduleAnalysis,
    advancedFilters: ProviderScheduleAnalysisAdvancedFilters,
  },
};
