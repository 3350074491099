import React, { memo } from "react";

import {
  TableLink,
  gridSpacing,
  GraphicCallout,
} from "@lucernahealth/lucerna-health-ui";
import equal from "fast-deep-equal/es6";
import { styled } from "styled-components";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";
import ProgressBarV2 from "../ProgressBar/ProgressBarV2";
import { colorTheme, numberWithCommas } from "~/src/utils";

type NumberChartProps = {
  title: string;
  numerator: number;
  numeratorStyle?: React.CSSProperties;
  denominator?: number;
  unit?: string;
  linkUrl?: string;
  linkText?: string;
  trendPercentage?: number;
  trendDirection?: "up" | "down";
  trendToolTip?: string;
  type: "metric" | "score" | "progress";
  getProgressBarColor?: (progress: number, isForeground: boolean) => string;
  subTitle?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
  icon?: string;
  iconColor?: string;
  small?: boolean;
  missing?: boolean;
};

const NumberChartWrapper = styled(Wrapper)`
  min-height: 0;
  min-width: 251px;
`;

const Container = styled.div<{ small?: boolean }>`
  font-family: Manrope;
  display: flex;
  font-weight: 700;
  font-size: ${({ small }) => (small ? "30px" : "36px")};
  color: ${colorTheme("neutral")};
  align-items: center;
`;

const NumeratorText = styled.div`
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
`;

const SubText = styled.span<{ small?: boolean }>`
  font-weight: 400;
  font-size: ${({ small }) => (small ? "14px" : "16px")};
  color: ${colorTheme("neutralL1")};
  margin-left: ${gridSpacing[1]}px;
`;

const NumberChart = memo(
  ({
    missing,
    title,
    numerator,
    numeratorStyle = {},
    denominator,
    unit,
    linkUrl,
    linkText,
    trendPercentage,
    trendDirection,
    trendToolTip,
    type,
    toolTip,
    subTitle,
    getProgressBarColor,
    loading,
    error,
    icon,
    iconColor,
    small,
  }: NumberChartProps) => {
    const _getProgressBarColor = (progress: number, isForeground: boolean) => {
      if (getProgressBarColor)
        return getProgressBarColor(progress, isForeground);

      if (progress === 0) {
        return colorTheme("neutralL4");
      } else if (progress >= 0.8) {
        return colorTheme(isForeground ? "danger" : "dangerL4");
      } else if (progress >= 0.5) {
        return colorTheme(isForeground ? "warning" : "warningL4");
      } else {
        return colorTheme(isForeground ? "success" : "successL4");
      }
    };

    const renderBody = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      if (missing) {
        return <GraphicCallout error="data" />;
      }

      if (!numerator && numerator !== 0) {
        return (
          <p
            style={{
              fontWeight: 700,
              fontSize: small ? 20 : 28,
              color: colorTheme("neutralL1"),
              marginTop: gridSpacing[4],
              marginLeft: gridSpacing[4],
            }}
          >
            -
          </p>
        );
      }

      if (type === "progress") {
        const ratio = numerator / (denominator || 1);
        const roundedRatio = Math.round(ratio * 100);

        return (
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: 28,
                color: colorTheme("neutral"),
              }}
            >
              {numberWithCommas(numerator)}
            </p>
            <ProgressBarV2
              progress={ratio}
              getColor={_getProgressBarColor}
              tooltipSuffix="filled"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: colorTheme("neutralL1"),
                }}
              >
                {roundedRatio}%
              </p>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: colorTheme("neutralL1"),
                  alignSelf: "flex-end",
                }}
              >
                {numberWithCommas(denominator)}
              </p>
            </div>
          </div>
        );
      } else if (type === "score") {
        const ratio = (numerator / (denominator || 1)) * 100;
        const roundedRatio = Math.round(ratio * 100) / 100;

        return (
          <Container small={small}>
            {roundedRatio} <SubText small={small}>%</SubText>
          </Container>
        );
      }

      // Default metric
      return (
        <Container small={small}>
          {icon && (
            <i
              className={icon}
              style={{
                color: iconColor ? iconColor : colorTheme("neutralL1"),
                marginRight: gridSpacing[2],
                fontSize: 24,
              }}
            />
          )}
          <NumeratorText style={numeratorStyle}>
            {numberWithCommas(numerator)}
          </NumeratorText>
          <SubText small={small}>
            {unit
              ? unit
              : denominator && ` out of ${numberWithCommas(denominator)}`}
          </SubText>
        </Container>
      );
    };

    return (
      <NumberChartWrapper
        style={{
          justifyContent: linkUrl && linkText ? "space-between" : "normal",
        }}
      >
        <Header
          title={title}
          toolTip={toolTip}
          trendDirection={trendDirection}
          trendPercentage={trendPercentage}
          trendToolTip={trendToolTip}
          subtitle={subTitle}
        />

        {renderBody()}
        {linkUrl && linkText && (
          <div style={{ marginTop: gridSpacing[5] }}>
            <TableLink url={linkUrl}>
              {linkText}
              <i
                className="fa-regular fa-arrow-right"
                style={{ marginLeft: gridSpacing[1] }}
              />
            </TableLink>
          </div>
        )}
      </NumberChartWrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default NumberChart;
