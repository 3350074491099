import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

type LineChartProps = {
  title: string;
  subtitle?: string;
  yAxisLabel: string;
  options: string[];
  data: {
    name: string;
    data: (number | null)[];
  }[];
  linkUrl?: string;
  linkText?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
};

const LineChart = memo(
  ({
    title,
    subtitle,
    yAxisLabel,
    options,
    data,
    linkUrl,
    linkText,
    toolTip,
    loading,
    error,
  }: LineChartProps) => {
    const renderLineChart = () => {
      if (error) {
        return <Error errorMessage={error} />;
      }

      if (loading) {
        return <Loading />;
      }

      const lineOptions: Highcharts.Options = {
        xAxis: {
          categories: options,
        },
        yAxis: {
          title: {
            text: yAxisLabel,
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        series: data as Highcharts.SeriesLineOptions[],
        legend: {
          layout: "horizontal",
          align: "left",
        },
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      return <HighchartsReact highcharts={Highcharts} options={lineOptions} />;
    };

    return (
      <Wrapper>
        <Header
          title={title}
          toolTip={toolTip}
          linkUrl={linkUrl}
          linkText={linkText}
          subtitle={subtitle}
        />
        {renderLineChart()}
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default LineChart;
