import React, { useEffect, useState } from "react";

import { PermissionDenied } from "@commonComponents";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useLocation, useNavigate } from "react-router";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

import InternalPagePanel from "./InternalPagePanel";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  min-height: 100vh;
  top: 0;
  right: 0;
  position: absolute;
  align-items: flex-end;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  transition: width 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 102;
`;

const DarkBackground = styled.div`
  transition: ${(props) =>
    props.$visible
      ? "opacity .2s linear"
      : "visibility 0s .2s, opacity .2s linear"};
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.$visible ? ".5" : "0")};
  position: absolute;
  top: 0;
  right: 600px;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background: ${colorTheme("black")};
`;

const InternalPage = ({ originalPath, routes, userPermissions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [width, setWidth] = useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const [route, setRoute] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const foundRoute = routes.find((route) => {
      let path = `${location.pathname}`;
      let pathUuid = path.match(
        /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/,
      );

      if (pathUuid) {
        path = path.replace(pathUuid, ":id");
      }

      return path.includes(route.path);
    });

    if (foundRoute) {
      setRoute(foundRoute);
      if (!isOpened) {
        setIsOpened(true);
        setWidth(0);
        setTimeout(() => setWidth("100%"), 1);
      }

      // Check permissions if it exists, else user has permissions
      if (foundRoute.permissions) {
        setHasPermission(
          foundRoute.permissions.some((permission) =>
            userPermissions.includes(permission),
          ),
        );
      } else {
        setHasPermission(true);
      }
    } else if (isOpened) {
      setWidth(0);
      setIsOpened(false);
      setTimeout(() => {
        setRoute(null);
        setHasPermission(false);
      }, 250);
    }
  }, [location.pathname]);

  let _SlideOutPanel = null;

  _SlideOutPanel = (
    <Wrapper
      style={{
        zIndex: isOpened ? 100 : -1,
      }}
    >
      <DarkBackground $visible={isOpened} />
      <Container
        style={{
          width,
          right: isOpened ? 0 : -65,
          overflow: "hidden",
          whiteSpace: isOpened ? "break-spaces" : "nowrap",
        }}
        id="internalPageContainer"
        onClick={(e) =>
          e.target.id === "internalPageContainer" && navigate(originalPath)
        }
      >
        {hasPermission && route && <route.component />}
        {!hasPermission && (
          <InternalPagePanel
            header="Permission Denied"
            closeFunc={() => navigate(originalPath)}
          >
            <PermissionDenied noPanel />
          </InternalPagePanel>
        )}
      </Container>
    </Wrapper>
  );

  return ReactDOM.createPortal(_SlideOutPanel, document.querySelector("body"));
};

InternalPage.propTypes = {
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.object,
};

export default InternalPage;
