import React, { useState } from "react";

import {
  BreadCrumbs,
  Button,
  gridSpacing,
  StatusPill,
  TableLink,
} from "@lucernahealth/lucerna-health-ui";
import { DATA_HUB_URLS } from "@urls";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { checkPermissions, colorTheme } from "@utils";

import ActivityStreamSlideOutButton from "~/src/App/Root/ActivityStream/SlideOutPanel/ActivityStreamSlideOutButton";
import ActivityStreamSlideOutPanel from "~/src/App/Root/ActivityStream/SlideOutPanel/ActivityStreamSlideOutPanel";

const Header = styled.div`
  flex-grow: 1;
  z-index: 103;
  background: ${colorTheme("neutralL6")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ height }) => height}px;
`;

const CloseIcon = styled.div`
  font-size: 20px;
  position: relative;
  margin-top: ${gridSpacing[4]}px;
  color: ${colorTheme("white")};
  z-index: 103;
  background: ${colorTheme("neutralL1")};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL3")};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: ${({ $alignItems }) => $alignItems};
  height: 100%;
  margin-top: ${({ $marginTop }) => $marginTop}px;
`;

const Shadow = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  z-index: 104;
  height: 4px;
  width: 100%;
  background: linear-gradient(
    rgba(9, 30, 66, 0.13) 0px,
    rgba(9, 30, 66, 0.13) 1px,
    rgba(9, 30, 66, 0.08) 1px,
    rgba(9, 30, 66, 0) 4px
  );
`;

const headerStyles = {
  1: {
    paddingTop: 24,
    paddingRight: 32,
    paddingBottom: 24,
    paddingLeft: 32,
    height: 24,
    shadowTop: 72,
    backgroundMarginTop: 76,
    titleMarginBottom: 0,
    buttonMarginTop: 0,
    buttonAlignItems: "center",
  },
  2: {
    paddingTop: 22,
    paddingRight: 32,
    paddingBottom: 18,
    paddingLeft: 32,
    height: 56,
    shadowTop: 96,
    backgroundMarginTop: 100,
    titleMarginBottom: gridSpacing[2],
    buttonMarginTop: 2,
    buttonAlignItems: "flex-start",
  },
  3: {
    paddingTop: 24,
    paddingRight: 32,
    paddingBottom: 20,
    paddingLeft: 32,
    height: 72,
    shadowTop: 116,
    backgroundMarginTop: 120,
    titleMarginBottom: gridSpacing[2],
    buttonMarginTop: 2,
    buttonAlignItems: "flex-start",
  },
};

const internalPageSizes = {
  3: "100%",
  2: "85%",
  1: "50%",
};

function InternalPagePanel({
  header,
  children,
  buttonsProps,
  style,
  closeFunc,
  activityStream,
  statusText,
  statusType,
  cleanStatusText,
  headerSubtext,
  headerSubtextOnClick,
  breadCrumbs,
  headerSize,
  customHeaderContent,
  headerIcons,
  lineageProps,
  testId,
  pageSize = 2,
  backFunc,
  headerStyle = {},
  shadowTop,
}) {
  const [slideOutIsOpened, setSlideOutIsOpened] = useState(false);
  const headerValues = headerStyles[headerSize] || headerStyles[1];

  const renderHeaderSubtext = () => {
    if (headerSubtext) {
      if (headerSubtextOnClick) {
        return (
          <div>
            <Button
              type="neutralLink"
              text={headerSubtext}
              style={{
                padding: 0,
                color: colorTheme("neutralL1"),
                fontSize: 14,
              }}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              color: colorTheme("neutralL1"),
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            {headerSubtext}
          </div>
        );
      }
    }
  };

  return (
    <>
      {activityStream && (
        <ActivityStreamSlideOutPanel
          isOpened={slideOutIsOpened}
          setIsOpened={setSlideOutIsOpened}
          app={activityStream?.app}
          model={activityStream?.model}
          object_id={activityStream?.object_id}
        />
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            flexGrow: "1",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: gridSpacing[2],
          }}
          onClick={closeFunc}
        >
          <CloseIcon id="closeInternalPageButton" onClick={closeFunc}>
            <i className="fa-regular fa-times" />
          </CloseIcon>
        </div>

        <div
          style={{
            width: "100%",
            background: colorTheme("white"),
            height: "100vh",
            position: "relative",
            maxWidth: `calc(${internalPageSizes[pageSize]} - 65px)`,
          }}
        >
          <Header
            height={headerValues.height}
            style={{
              paddingTop: headerValues.paddingTop,
              paddingBottom: headerValues.paddingBottom,
              paddingLeft: headerValues.paddingLeft,
              paddingRight: headerValues.paddingRight,
              ...headerStyle,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {backFunc && (
                <Button
                  mr={3}
                  type="secondary"
                  icon="fa-angle-left"
                  style={{
                    height: 34,
                    width: 8,
                  }}
                  onClick={backFunc}
                />
              )}
              {customHeaderContent}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {breadCrumbs && (
                  <BreadCrumbs
                    style={{ paddingTop: 0, marginBottom: gridSpacing[2] }}
                    routes={breadCrumbs}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: headerValues.titleMarginBottom,
                  }}
                >
                  <h4 data-testid={`${testId}-header-text`}>{header}</h4>
                  {statusText && (
                    <StatusPill
                      style={{ marginLeft: gridSpacing[2] }}
                      text={statusText}
                      type={statusType}
                      cleanText={cleanStatusText}
                    />
                  )}
                  {headerIcons}
                </div>
                {renderHeaderSubtext()}
              </div>
            </div>
            <ButtonContainer
              $alignItems={headerValues.buttonAlignItems}
              $marginTop={headerValues.buttonMarginTop}
            >
              {lineageProps?.id &&
                lineageProps?.objectType &&
                checkPermissions("lineage.view_lineagegraph") && (
                  <TableLink
                    noUnderline
                    newTab
                    url={`${DATA_HUB_URLS.DATA_MODEL_LINEAGE_GRAPH}?object_type=${lineageProps.objectType}&id=${lineageProps.id}`}
                  >
                    <Button
                      style={{ marginLeft: gridSpacing[2] }}
                      icon="fa-diagram-project"
                      type="secondary"
                    />
                  </TableLink>
                )}
              {buttonsProps
                ?.filter((button) => !button.hidden)
                .map((button, i) => (
                  <Button
                    ml
                    key={i}
                    {...button}
                    style={{
                      ...button?.style,
                    }}
                  />
                ))}
              {activityStream && (
                <ActivityStreamSlideOutButton
                  style={{ marginLeft: gridSpacing[2] }}
                  setSlideOutIsOpened={setSlideOutIsOpened}
                />
              )}
            </ButtonContainer>
          </Header>
          <Shadow top={shadowTop ?? headerValues.shadowTop} />
          <div
            style={{
              background: colorTheme("white"),
              padding: gridSpacing[6],
              position: "relative",
              overflow: "auto",
              height: `calc(100vh - ${headerValues.height}px - ${
                gridSpacing[6] * 2
              }px - ${headerValues.paddingTop}px - ${
                headerValues.paddingBottom
              }px)`,
              ...style,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

InternalPagePanel.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  buttonsProps: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
  closeFunc: PropTypes.func,
  statusText: PropTypes.string,
  statusType: PropTypes.string,
  cleanStatusText: PropTypes.bool,
  headerSubtext: PropTypes.string,
  headerSubtextOnClick: PropTypes.func,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      breadcrumb: PropTypes.any.isRequired,
      onClick: PropTypes.func,
    }),
  ),
  customHeaderContent: PropTypes.any,
  headerIcons: PropTypes.any,
  activityStream: PropTypes.shape({
    app: PropTypes.string,
    model: PropTypes.string,
    object_id: PropTypes.string,
  }),
  headerSize: PropTypes.oneOf([1, 2, 3]),
  lineageProps: PropTypes.shape({
    objectType: PropTypes.string,
    id: PropTypes.string,
  }),
  testId: PropTypes.string,
  pageSize: PropTypes.oneOf([3, 2, 1]),
  backFunc: PropTypes.func,
  headerStyle: PropTypes.object,
  shadowTop: PropTypes.number,
};

export default InternalPagePanel;
